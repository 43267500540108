export const HTTP_STATUS_CODES = {
  OK: 200,
  MULTIPLE_CHOICE: 300,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  NO_RESPONSE: 444,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
} as const;

export const INTERCEPTION_HTTP_STATUSES = [
  HTTP_STATUS_CODES.BAD_REQUEST,
  HTTP_STATUS_CODES.UNAUTHORIZED,
  HTTP_STATUS_CODES.FORBIDDEN,
  HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR,
  HTTP_STATUS_CODES.BAD_GATEWAY,
  HTTP_STATUS_CODES.SERVICE_UNAVAILABLE,
] as const;

export const typesOfElement = {
  button: 'button', // <button>
  link: 'link', // <NuxtLink>
} as const;

export const typeOfState = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  warn: 'warn',
  error: 'error',
  red: 'red',
  ghost: 'ghost',
} as const;

export const DROPDOWN_VISIBLE_ITEMS_NUMBER_DEFAULT = 4 as const;

export const SCREENS = {
  SM: 320,
  MD: 768,
  LG: 1024,
  XL: 1280,
} as const;

export const BOOKING_STATUSES = {
  CONFIRMED: 'CONFIRMED',
  CANCELLED: 'CANCELLED',
  IN_PROGRESS: 'IN_PROGRESS',
  PAST_BOOKING: 'PAST_BOOKING',
  incomplete: 'incomplete',
  complete: 'complete',
  draft: 'draft',
  new: 'new',
  submitted: 'submitted',
  edited: 'edited',
  ACTION_REQUIRED: 'ACTION_REQUIRED'
} as const;

export const HTTP_ERROR_TYPES = {
  BOOKING_ALREADY_CANCELLED: 'booking_already_cancelled',
} as const;

export const DOWNLOAD_ERROR_MESSAGES = {
  notfound: 'ticketsDownload.errorMessages.notfound',
  default: 'ticketsDownload.errorMessages.default',
} as const;

export const PHONE_DEFAULT_FORMAT = '______________' as const;
export const US_PHONE_DEFAULT_FORMAT = '(___) ___ - ____' as const;

export const THEMES = {
  PODS: 'PODS',
  PARKINGS: 'PARKINGS',
} as const;

export const AUTH0_COOKIE_PREFIXES = {
  WL: 'auth-wl.',
  PARTNERS: 'auth-ex.',
} as const;

export const PARTNER_TYPES = {
  providerAndWhitelabel: 1,
  providerOnly: 2,
  channel: 3,
  apiProvider: 4,
  whiteLabelOnly: 5,
  bookingPortal: 6,
} as const;

export const PARTNER_TYPES_CODES = {
  WL_ONLY: 'wl_only',
  PROVIDER_AND_WHITE_LABEL: 'provider_and_white_label',
} as const;

export const FORM_TYPES = {
  PODS: 'ESCAPE_PODS',
  PARKINGS: 'SIMPLE_PARKINGS',
  TRANSFERS: 'SHUTTLE_FINDER',
} as const;

export const STRIPE_LOCALIZATION = 'en' as const;

export const PROGRESS_INDICATOR_PAGES = {
  search: 'index',
  checkout: 'checkout',
  choose: 'search',
  extras: 'select-extras',
  travel_extras: 'travel-extras',
  extras_lounges: 'select-extras-lounges',
  extras_product: 'select-extras-product',
  payment: 'payment',
} as const;

export const MY_BOOKINGS_REDIRECTION_PAGES = {
  search: 'index',
  choose: 'search',
  extras: 'select-extras',
} as const;

export const enum CookiesNames {
  manage = 'manageBookingToken',
}

export const SEARCH_BOX_SESSION_DURATION: Readonly<number> = 120; // minutes
export const SELECT_TERMINAL_ERROR_DURATION: Readonly<number> = 7000; // seconds
export const CART_REVALIDATION_INTERVAL: Readonly<number> = 900; // seconds, 15 minutes
export const PAYMENT_EMPTY_CART_ERROR_DURATION: Readonly<number> = 7000; // seconds
export const REQUEST_FREEZE_CHECK_TIME: Readonly<number> = 60000; // miliseconds, 1 minute
export const UPDATE_DETAILS_MESSAGE_TIME_ALIVE: Readonly<number> = 60000; // miliseconds, 1 minute
export const LOCAL_ERROR_SHOW_TIME: Readonly<number> = 7000; // 7 seconds

export const PODS_SEARCH_DEFAULT_TIME: Readonly<string> = '00:00';

export const MIDDAY: Readonly<string> = '12:00';

export const DEFAULT_MAP_ZOOM: Readonly<number> = 17;

export const SEARCH_RESULTS_LIFETIME: Readonly<number> = 15; // minutes
export const PODS_DEFAULT_BOOKING_DURATION: Readonly<number> = 15; // minutes

export const PAYMENT_FORM_DATA_LIFETIME: Readonly<number> = 120; // minutes

export const PODS_AVAILIBLE_BOOKING_SLOTS = [
  { duration: 15, price: 10 },
  { duration: 30, price: 15 },
  { duration: 45, price: 25 },
  { duration: 60, price: 30 },
  { duration: 90, price: 45 },
  { duration: 120, price: 60 },
] as const;

export const PAYMENT_FORM_DATA = {
  firstname: '',
  lastname: '',
  email: '',
  phone: {
    phoneNumber: '',
    formattedNumber: '',
    countryCode: '',
  },
} as const;

export const MAX_COUNT_CART: Readonly<number> = 5;

export const MODAL_ERROR_MESSAGES = {
  reload_page: `Please, try again and reload the page <br> If it still doesn’t work -<a href='${process.env['NUXT_ENV_CONTACT_PAGE_URL']}' class="text-highlighted">&nbsp;Contact Us</a>`,
  max_limit:
    'Sorry, this item can’t be added because you have the maximum number of 5 items in your cart.<br> Please remove an item from your cart and try again.',
  same_pod: 'Looks like you already added this Escape Pod to the cart',
  customer_service: `Please contact <a href='${process.env['NUXT_ENV_CONTACT_PAGE_URL']}' class="text-highlighted">customer service</a>`,
  booking_connection_lost:
    'Sorry, something went wrong. Looks like the network connection was lost.<br> You will receive the confirmation email if your booking was completed successfully',
} as const;

export const COLORS = {
  PODS_BRAND: '#38A4A2',
  MAG_ELEMENTS_ICONS_ON_DARK: '#64687E',
} as const;

export const PRODUCT_CODES = {
  pods: 'pods',
} as const;

export const STRIPE_JS: Readonly<string> = 'https://js.stripe.com/v3';

export const UNSUPPORTED_BROWSERS = ['Internet Explorer'] as const;

export const STRIPE_ERROR_CODES = {
  incomplete_number: 'incomplete_number',
  incomplete_expiry: 'incomplete_expiry',
  incomplete_cvc: 'incomplete_cvc',
} as const;

export const NAMED_ROUTES = {
  home: '/',
  maintenance: '/maintenance',
  cart: '/cart',
  select_extras: {
    default: '/select-extras',
    lounges: '/select-extras/lounges',
    fasttrack: '/select-extras/fasttrack',
    product: '/select-extras/product',
  },
  travel_extras: '/travel-extras',
  payment: '/payment',
  receipt: '/receipt',
  search: '/search',
  select_terminal: '/select-terminal',
  unsupported_browser: '/unsupported-browser',
  manage_booking: {
    default: '/manage-booking',
    search: '/search-booking',
    update_details: '/manage-booking/update-details',
    cancel: '/manage-booking/cancel',
    cancel_receipt: '/manage-booking/cancel/receipt',
  },
  downloads: {
    ticket: '/downloads/ticket/',
    error: '/downloads/error/',
  },
  booking_products: {
    home: '/booking-products',
  },
  booking_guest_details: {
    home: '/guests-details',
    questionnaire: '/guests-details/questionnaire',
  },
  booking_baggage_details: {
    home: '/baggage-details',
  },
  my_profile: '/my-profile',
  not_found: '/404',
} as const;

export const ROUTE_NAMES = {
  booking_app: {
    home: 'booking-home',
    search: 'booking-search',
    select_extras: 'booking-select-extras',
  },
  home: 'index',
  maintenance: 'maintenance',
  cart: 'cart',
  select_extras: 'select-extras',
  travel_extras: 'travel-extras',
  payment: 'payment',
  receipt: 'receipt',
  search: 'search',
  select_terminal: 'select-terminal',
  unsupported_browser: 'unsupported-browser',
  manage_booking: {
    default: 'manage-booking',
    search: 'search-booking',
    update_details: 'manage-booking-update-details',
    cancel: 'manage-booking-cancel',
    cancel_receipt: 'manage-booking-cancel-receipt',
  },
  my_profile: 'my-profile',
  not_found: '404',
} as const;

export const PARKINGS_TIMEPICKER_STEPS: Readonly<number> = 60; // minutes
export const LOUNGES_TIMEPICKER_STEPS: Readonly<number> = 15; // minutes
export const LOUNGES_CATEGORY_TYPES = {
  ALL: 'all',
  CHECKED_BAGGAGE: 'checked_baggage',
  CABIN_BAGGAGE: 'cabin_baggage',
} as const;

export const PAYPAL_MAXIMUM_DAYS_FORM_PAYMENT: Readonly<number> = 180; // Days

export const SECURITY_TOKEN_EXPIRATION: Readonly<number> = 1800; // 30 minutes
export const SECURITY_CHECK_INTERVAL: Readonly<number> = 30000; // 30 seconds

export const BARRIER_ACCESS_TYPES = {
  QRCODE: 'QrCode',
  BARCODE: 'BarCode',
  PINCODE: 'Pin',
  ANPR: 'anpr',
  NONE: 'none',
} as const;

export const FALLBACK_LOCALE = 'en-US' as const;

export const PARKING_CATEGORY_TYPES = {
  ALL: 'all',
  ON_AIRPORT: 'category_type_on_airport',
  OFFSITE_PARK_AND_RIDE: 'category_type_offsite',
  MEET_AND_GREET_NO_TRANSFER: 'category_type_no_transfer',
} as const;

export const PARKING_SORT_OPTIONS = {
  RECOMMENDED: 'recommended',
  PRICE: 'price',
  DISTANCE: 'distance',
} as const;

export const enum MANAGE_BOOKING_KEYS {
  search = 'search',
  resend = 'resend',
}

export const defaultActiveTab: Readonly<MANAGE_BOOKING_KEYS.search> =
  MANAGE_BOOKING_KEYS.search;

export const tabs = [
  {
    key: MANAGE_BOOKING_KEYS.search,
    text: 'Search for booking',
  },
  {
    key: MANAGE_BOOKING_KEYS.resend,
    text: 'Resend confirmation',
  },
] as const;

/**
 * Used to work out what profile setting to show
 * @enum
 */
export const enum PROFILE_SETTINGS_COMPONENTS {
  name = 'FullName',
  phone = 'Phone',
  vehicle = 'Vehicle',
  password = 'Password',
  postcode = 'PostalCode',
  mp = 'MarketingPreferences',
}

export const enum GA_IDs {
  FIND_BOOKING = 'GAFindBooking',
  NOT_FOUND = 'GABookingNotFound',
}

export const PAYMENT_PROVIDERS = {
  BRAINTREE: 'braintree',
  STRIPE: 'stripe',
  WINDCAVE: 'windcave',
} as const;

export const PAYMENT_METHOD_STRATEGIES = {
  NEW_PAYMENT_ONLY: 'NewPaymentOnly',
  SAVE_CARD_PAYMENT_METHOD: 'SaveCardPaymentMethod',
} as const;

export const PAYMENT_PROVIDER_COLORS = {
  MAG_ELEMENTS_ICONS_ON_DARK: '#64687E',
  STRIPE: {
    PRIMARY: '#ff0000',
    BACKGROUND: '#ffffff',
    TEXT: '#7B8297',
    DANGER: '#df1b41',
  },
} as const;

export const PAYMENT_METHODS = {
  BRAINTREE: {
    HOSTED_FIELDS: 'braintree-hosted-fields',
    PAYPAL: 'braintree-paypal',
  },
  STRIPE: {
    HOSTED_FIELDS: 'stripe-hosted-fields',
  },
  WINDCAVE: {
    HOSTED_FIELDS: 'windcave-hosted-fields',
  },
} as const;

export const enum PaymentMethods {
  StripeHostedFields = 'stripe-hosted-fields',
  BraintreeHostedFields = 'braintree-hosted-fields',
  BraintreePaypal = 'braintree-paypal',
}
export const TEXT_INPUT_DEBOUNCE_TIME = 250;

export const ContinueBookingStatuses = {
  Successful: 'successful',
  Failed: 'failed',
};

export const COLOUR_SCHEMES = {
  LIGHT: 'light',
  DARK: 'dark',
} as const;

export const APP_HERO_PRODUCTS = {
  LOUNGES: 'lounges',
  PARKING: 'parking',
  FASTTRACK: 'fasttrack',
  TRAVEL_INSURANCE: 'travel-insurance',
} as const;

export const APP_HERO_PRODUCT_NAMES = {
  LOUNGES: 'Lounges',
  PARKING: 'Parking',
  FASTTRACK: 'Fast track',
  TRAVEL_INSURANCE: 'Travel Insurance',
} as const;

type HeroProducts = typeof APP_HERO_PRODUCTS;
export type HeroProduct = HeroProducts[keyof HeroProducts];

export const PRODUCT_TYPES = {
  ...APP_HERO_PRODUCTS,
  EXTRAS: 'extras',
} as const;

export const PRODUCT_TYPE_NAMES = {
  ...APP_HERO_PRODUCT_NAMES,
  EXTRAS: '+ Extras',
} as const;

type AppProductTypes = typeof PRODUCT_TYPES;
export type ProductType = AppProductTypes[keyof AppProductTypes];

export const APP_LABELS = {
  ACCOUNT: 'account',
  CHECKOUT: 'checkout',
  ECOMMERCE: 'ecommerce',
} as const;

export const BAGGAGE_SELECTOR_OPTIONS = {
  checked_baggage: 'checked_baggage',
  cabin_baggage: 'cabin_baggage',
  none: 'none',
} as const;

export const VEHICLE_OCCUPANTS = [
  {
    id: 0,
    name: '0',
  },
  {
    id: 1,
    name: '1',
  },
  {
    id: 2,
    name: '2',
  },
  {
    id: 3,
    name: '3',
  },
  {
    id: 4,
    name: '4',
  },
  {
    id: 5,
    name: '5',
  },
  {
    id: 6,
    name: '6',
  },
  {
    id: 7,
    name: '7',
  },
  {
    id: 8,
    name: '8',
  },
] as const;
